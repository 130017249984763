import React from "react";

export const InputWithIcon = ({
  icon,
  placeholder,
}: {
  icon: string;
  placeholder: string;
}) => {
  return (
    <div className="">
      <span className="relative ">
        <img src={icon} className="absolute top-0 left-3" />
        <input
          placeholder={placeholder}
          className="bg-[#F5F5F5] rounded-lg py-2 px-10 w-full"
        />
      </span>
    </div>
  );
};
