import { toast } from "react-toastify";
import { getConnectionId } from "utils/localStorageServices";

export const RecordTimeline = (
  connection,
  leavingSlideId,
  source,
  timeSpent,
  previousSlideId
) => {
  if (connection) {
    const presentationId = localStorage.getItem("presentationId");

    // console.log(
    //   "RecordTimeline",
    //   presentationId,
    //   getConnectionId(),
    //   leavingSlideId,
    //   source,
    //   timeSpent,
    //   previousSlideId
    // );

    connection
      .invoke(
        "RecordTimeline",
        presentationId,
        getConnectionId(),
        leavingSlideId,
        source,
        timeSpent,
        previousSlideId
      )
      .catch((err) => {
        toast.error("An error occured while recording timeline");
      });
  }
};
