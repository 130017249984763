import React from "react";
import copyLink from "assets/svg/copyLInk.svg";

export const CopyWrapper = ({ text }: { text: string }) => {
  return (
    <span className="py-2 px-4 rounded-lg cursor-pointer bg-[#E6F2ED] flex items-center gap-2">
      <span>
        <img src={copyLink} />
      </span>
      <p className="text-black text-sm font-grotesk font-semibold">{text}</p>
    </span>
  );
};
