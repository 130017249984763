// Assets
// import laptopIMG from 'assets/image/laptopIMG.png';
import ellipsis from 'assets/svg/ellipsis-horizontal.svg';
import presentationIcon from 'assets/svg/presention-chart-icon.svg';
import eyeIcon from 'assets/svg/eye.svg';
import inboxIcon from 'assets/svg/inbox-arrow-down.svg';
import moveFile from 'assets/svg/move.svg';
// import search from 'assets/svg/search.svg';
// import dot from 'assets/svg/_Dot.svg';
// import laptopIMG from 'assets/image/laptopIMG.png';
// import sendSquareBTN from 'assets/svg/send-sqaure-2.svg';
// import plusIcon from 'assets/svg/plus-small.svg';
// import sortIcon from 'assets/svg/sortIcon.svg';
// import docText from 'assets/svg/document-text.svg';
// import dots from 'assets/svg/dots.svg';
import trash from 'assets/svg/trash.svg';
import rename from 'assets/svg/rename.svg';
// import metrics from 'assets/svg/metrics.svg';
import updateThumb from 'assets/svg/updateThumb.svg';
import link from 'assets/svg/link.svg';
// import access from 'assets/svg/access.svg';
import details from 'assets/svg/details.svg';

// Libraries
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';

//
import FileThumbnail from 'components/Admin/BriefCase/FileThumbnail';
import IsPrivate from 'components/Admin/BriefCase/IsPrivate';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import { removeBriefcaseFile } from 'store/Briefcase/briefcaseSlice';

interface BriefcaseDocuments {
  spaceId: string;
  documentId: string;
  groupId: string | null;
  isPrivate: boolean;
  title: string;
  url: string;
  views: number;
  downloads: number;
  id: string;
}

export default function AllFiles({
  spaceId,
  briefcaseDocuments,
}: {
  spaceId: string;
  briefcaseDocuments: BriefcaseDocuments[];
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  // Functions
  const handleOptionsClick = (index: number, action: string) => {
    if (action === 'remove') {
      console.log('Removed!!!');
      const documentId = briefcaseDocuments[index].documentId;
      dispatch(removeBriefcaseFile({ spaceId, documentId }));

      return;
    }
    if (action === 'rename') {
      return;
    }
  };

  //variables
  const items = [
    {
      key: '1',
      action: 'details',
      label: (
        <div className='flex gap-x-2'>
          <img src={details} alt='create new folder' className='w-4' />
          <p>Details</p>
        </div>
      ),
    },
    {
      key: '2',
      action: 'move',
      label: (
        <div className='flex gap-x-2'>
          <img src={moveFile} alt='rename file' className='w-4' />
          <p>Move file</p>
        </div>
      ),
    },
    {
      key: '3',
      action: 'rename',
      label: (
        <div className='flex gap-x-2'>
          <img src={rename} alt='rename file' className='w-4' />
          <p>Rename</p>
        </div>
      ),
    },

    // {
    //   key: '2',
    //   label: (
    //     <div className='flex gap-x-2'>
    //       <img src={metrics} alt='metrics file' className='w-4' />
    //       <p>Metrics</p>
    //     </div>
    //   ),
    // },

    {
      key: '4',
      action: 'updateThumbnails',
      label: (
        <div className='flex gap-x-2'>
          <img src={updateThumb} alt='metrics file' className='w-4' />
          <p>Update thumbnails</p>
        </div>
      ),
    },

    // {
    //   key: '5',
    //   label: (
    //     <div className='flex gap-x-2'>
    //       <img src={access} alt='access control' className='w-4' />
    //       <p>Access control</p>
    //     </div>
    //   ),
    // },

    {
      key: '5',
      action: 'share',
      label: (
        <div className='flex gap-x-2'>
          <img src={link} alt='share file' className='w-4' />
          <p>Share</p>
        </div>
      ),
    },

    {
      key: '6',
      action: 'remove',
      label: (
        <div className='flex gap-x-2'>
          <img src={trash} alt='delete file' className='w-4' />
          <p>Remove</p>
        </div>
      ),
    },
  ];

  const columns = [
    {
      header: 'Name',
      field: 'name',
      body: (rowData: BriefcaseDocuments) => {
        return (
          <section
            className='w-[400px] cursor-pointer'
            onClick={() => {
              navigate(`/admin/mybriefcase/details/${spaceId}/${rowData.documentId}`);
            }}
          >
            <section className='flex items-center gap-x-4'>
              <div className='bg-gray-800 text-white text-xs rounded-lg overflow-hidden w-[36px] h-[36px] flex items-center justify-center'>
                {/* <img
                  src={rowData.url}
                  alt='profile'
                  className='w-full h-full'
                /> */}
                <FileThumbnail
                  file={rowData.documentId}
                  height={49}
                  width={49}
                />
              </div>
              <p className='text-black text-sm'>{rowData?.title}</p>
            </section>
          </section>
        );
      },
    },
    {
      header: 'Actions',
      field: 'actions',
      sortable: true,
      body: (rowData: BriefcaseDocuments) => {
        // const { actions } = rowData ?? {};
        return (
          <section className='w-full flex gap-x-2'>
            <div className='flex gap-x-2'>
              <div className='flex items-center gap-x-2 bg-[#F6F3EF] border border-[#DFDFDF] rounded-full w-fit px-2 py-1 min-w-14 justify-center'>
                <img src={eyeIcon} alt='profile' className='w-4' />
                <div className='text-sm font-medium capitalize'>
                  <p className='text-xs'>{rowData.views}</p>
                </div>
              </div>
              <div className='flex items-center gap-x-2 bg-[#F6F3EF] border border-[#DFDFDF] rounded-full w-fit px-2 py-1 min-w-14 justify-center'>
                <img src={inboxIcon} alt='profile' className='w-4' />
                <div className='text-sm font-medium capitalize'>
                  <p className='text-xs'>{rowData.downloads}</p>
                </div>
              </div>
              <div className='flex items-center gap-x-2 bg-[#F6F3EF] border border-[#DFDFDF] rounded-full w-fit px-2 py-1 min-w-14 justify-center'>
                <img src={presentationIcon} alt='profile' className='w-4' />
                <div className='text-sm font-medium capitalize'>
                  {/* <p className='text-xs'>{actions.presented}</p> */}
                  <p className='text-xs'>0</p>
                </div>
              </div>
            </div>
          </section>
        );
      },
    },

    {
      header: 'Permission',
      field: 'permission',
      body: (rowData: BriefcaseDocuments) => {
        return (
          <section className='flex gap-x-5 items-center'>
            <p className='text-sm'>Private</p>
            <IsPrivate
              isPrivate={rowData.isPrivate}
              spaceId={spaceId}
              documentId={rowData.documentId}
            />
          </section>
        );
      },
    },

    {
      header: '',
      sortable: true,
      body: (rowData: any, options: { rowIndex: number }) => {
        return (
          <div className='w-[100px] flex justify-end cursor-pointer'>
            {/* <section onClick={(e) => e.preventDefault()}>
              <Dropdown
                trigger={['click']}
                dropdownRender={(menu) => (
                  <Menu>
                    {items.map((item) => {
                      return <Menu.Item key={item.key}>{item.label}</Menu.Item>;
                    })}
                  </Menu>
                )}
              >
                <div className='cursor-pointer'>
                  <img src={ellipsis} alt='options' />
                </div>
              </Dropdown>
            </section> */}
            <section onClick={(e) => e.preventDefault()}>
              <Dropdown
                trigger={['click']}
                dropdownRender={(menu) => (
                  <Menu>
                    {items.map((item) => {
                      return (
                        <Menu.Item
                          key={item.key}
                          onClick={() => {
                            handleOptionsClick(options.rowIndex, item.action);
                          }}
                        >
                          {item.label}
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                )}
              >
                <div className='cursor-pointer py-4'>
                  <img src={ellipsis} alt='options' />
                </div>
              </Dropdown>
            </section>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {/* Table */}
      <section className='overflow-hidden rounded-xl w-full'>
        <DataTable
          globalFilterFields={['name', 'lastUpdated', 'tags']}
          value={briefcaseDocuments}
          tableStyle={{ minWidth: '45rem', maxHeight: '300px' }}
          size={'normal'}
        >
          {columns.map((col, index) => {
            return (
              <Column
                key={index}
                body={col?.body}
                field={col.field}
                header={col.header}
                headerClassName='bg-[#F5F5F5F5] text-blue300 font-normal text-sm'
                className='text-sm'
              />
            );
          })}
        </DataTable>
      </section>
    </div>
  );
}
