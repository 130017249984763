import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import "primereact/resources/themes/saga-blue/theme.css"; // Optional, for a PrimeReact theme
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { ActiveElement, ChartEvent } from "chart.js";
import arrowLeft from "assets/svg/leftArrow.svg";

export const LineChart = ({
  labels,
  data,
  payload,
}: {
  labels: string[];
  data: number[];
  payload?: any;
}) => {
  // Line Chart Data
  const [sLabels, setSLabels] = useState(labels);
  const [sData, setSData] = useState<number[]>(data);
  const [plabels, setPLabels] = useState<string[]>([]);
  const [pData, setPData] = useState<number[]>([]);
  const [chartOption, setChartOption] = useState({});
  const [lineData, setLineData] = useState({});
  const [dataSwitched, setDataSwitched] = useState(false);

  useEffect(() => {
    const lineData = {
      labels: sLabels,
      datasets: [
        {
          data: sData,
          fill: false, // Prevent the area under the line from being filled
          borderColor: "#E86025", // Line color
          tension: 0.4, // Makes the line a bit smoother, you can adjust this for more or less curve
        },
      ],
    };

    const lineOptions = {
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 1,
      onClick: (event: ChartEvent, elements: ActiveElement[]) => {
        const activeIndex = elements[0].index;
        if (payload[activeIndex].data.length && !dataSwitched) {
          const data_ = payload[activeIndex].data;
          const newLabel = data_.map((item: any) => item.key);
          const newData = data_.map((item: any) => item.value);
          setPData(sData);
          setPLabels(sLabels);
          setSData((prev) => newData);
          setSLabels((prev) => newLabel);
          setDataSwitched(true);
        }
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
      scales: {
        x: {
          display: true,
          offset: true,
          title: {
            display: false,
            text: "Months",
          },
          grid: {
            display: false,
          },
          min: 0,
        },
        y: {
          display: true,
          suggestedMin: 0,
          min: 0,
          title: {
            display: false,
            text: "Documents",
          },
        },
      },
    };
    console.log("got in here to trigger");
    setChartOption((prev) => lineOptions);
    setLineData((prev) => lineData);
  }, [sData, sLabels]);

  // Line chart Data

  console.log(chartOption, "New Chart Option value heree");
  console.log(lineData, "New Line data value heree");

  const doReCalibrate = () => {
    setSData(pData);
    setSLabels(plabels);
    setDataSwitched(false);
    setPData([]);
    setPLabels([]);
  };
  return (
    <div>
      <div className="flex items-center justify-between mb-3">
        {dataSwitched && (
          <div onClick={doReCalibrate} className="cursor-pointer">
            <img src={arrowLeft} />
          </div>
        )}
      </div>
      <Chart type="line" data={lineData} options={chartOption} />
    </div>
  );
};
