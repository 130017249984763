import { useEffect, useState } from 'react';
import {
  getBriefcaseDocuments,
  setBriefcaseDocumentPermission,
} from 'store/Briefcase/briefcaseSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';

// Types
interface IsPrivateProps {
  isPrivate: boolean;
  spaceId: string;
  documentId: string;
}

export default function IsPrivate({
  isPrivate,
  spaceId,
  documentId,
}: IsPrivateProps) {
  const dispatch = useDispatch<AppDispatch>();
  // States
  const [private_, setPrivate_] = useState(isPrivate);

  //  Functions
  const handleIsPrivate = () => {
    const data = {
      isPrivate: !private_,
      documentId: documentId,
    };
    dispatch(setBriefcaseDocumentPermission({ spaceId, data })).then(() =>
      setPrivate_(!private_)
    );
    // spaceId && dispatch(getBriefcaseDocuments(spaceId));
    // .then(
    //   ({ payload }) => {
    //     if (payload) {
    //       setPrivate_(!private_);
    //       //   updating the store
    //       spaceId && dispatch(getBriefcaseDocuments(spaceId));
    //       // toast.current.show({
    //       //   severity: 'success',
    //       //   summary: 'Success',
    //       //   detail: `${fileData.name} uploaded successfully`,
    //       // });
    //     } else {
    //       // toast.current.show({
    //       //   severity: 'error',
    //       //   summary: 'Error',
    //       //   detail: `${fileData.name} failed to upload`,
    //       // });
    //     }
    //   }
    // );
  };

  return (
    <label className='inline-flex items-center cursor-pointer'>
      <input
        type='checkbox'
        value=''
        checked={private_}
        className='sr-only peer'
        onChange={handleIsPrivate}
      />
      <div className="relative w-11 h-6 bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-black"></div>
    </label>
  );
}
