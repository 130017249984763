import Axios from 'lib/axiosInterceptor';

//get
export const _getBriefcases = (teamId) => {
  return Axios.get(`/Spaces?teamId=${teamId}`);
};

export const _getBriefcase = (spaceId, teamId) => {
  return Axios.get(`/Spaces/Get/${spaceId}?teamId=${teamId}`);
};

export const _getBriefcaseDocuments = (spaceId) => {
  return Axios.get(`/Spaces/Documents/${spaceId}`);
};

export const _getBriefcaseShareHistory = (spaceId) => {
  return Axios.get(`/Report/Spaces/History/${spaceId}?type=${0}`);
};

export const _getBriefcasePresentationHistory = (spaceId) => {
  return Axios.get(`/Report/Spaces/History/${spaceId}?type=${1}`);
};

// looki into this, endpoint 401 error
export const _getDocumentVisits = (spaceId, documentId) => {
  return Axios.get(`/Report/Document/Visits/${documentId}?spaceId=${spaceId}`);
};

//post
export const _createBriefcase = (data) => {
  return Axios.post('/Spaces/Create', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const _updateBriefcase = (data) => {
  return Axios.post('/Spaces/Update', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const _addBriefcaseFile = (spaceId, data) => {
  return Axios.post(`/Spaces/Documents/Add/${spaceId}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const _removeBriefcaseFile = (spaceId, documentId) => {
  return Axios.post(
    `/Spaces/Documents/Remove/${spaceId}?documentId=${documentId}`
  );
};

export const _setBriefcaseDocumentPermission = (spaceId, data) => {
  return Axios.post(`/Spaces/Documents/SetPermission/${spaceId}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const _removeBriefcase = (spaceId, archive) => {
  return Axios.post(`/Spaces/Delete/${spaceId}?archive=${archive}`, {});
};
