import React, { useEffect, useRef, useState } from 'react';

// Components
import AllFiles from './Tables/AllFiles';

// Assets
import dot from 'assets/svg/_Dot.svg';
import ellipsisVertical from 'assets/svg/ellipsis-vertical.svg';
import bell from 'assets/svg/bell.svg';
import menu from 'assets/svg/hamburger.svg';
import briefcaseFrame from 'assets/image/briefcaseFrame.png';
import clock from 'assets/svg/clock-icon.svg';
import briefcaseIMG from 'assets/svg/briefcase-img.svg';
import ChevronDownWhite from 'assets/svg/chevron-down-white.svg';
import plusWhite from 'assets/svg/plus-white.svg';
import lines from 'assets/image/briefcaselayerlines.png';
import sendIcon from 'assets/svg/send-icon.svg';
import ChevronOrange from 'assets/svg/chevron-down-orange.svg';
import link from 'assets/svg/link2.svg';
import addCircle from 'assets/svg/add-circle2.svg';
import divider from 'assets/svg/divider.svg';
import addCircleOrange from 'assets/svg/add-circle-orange.svg';

// Libraries
import { AvatarGroup } from 'primereact/avatargroup';
import { Avatar } from 'primereact/avatar';
import { Link, useSearchParams, useParams } from 'react-router-dom';
import ShareHistory from './Tables/ShareHistory';
import AuditTrail from './Tables/AuditTrail';
import Analytics from './Tables/Analytics';
import { Drawer, Dropdown, Menu, Modal } from 'antd';
import ShareBriefcase from './Modals/ShareBriefcase';
import UploadDocuments from './Modals/UploadDocuments';
import {
  getBriefcase,
  getBriefcaseDocuments,
  getPresentationHistory,
  getShareHistory,
} from 'store/Briefcase/briefcaseSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import Loader from 'components/UI/Loader';
import { getInitials } from 'utils/formatter';
import { getTeamInfo } from 'store/Teams/teamsSlice';
import PresentationHistory from './Tables/PresentationHistory';
import AddMembers from './Modals/AddMembers';

//Types
interface UploadModalREf {
  resetModal: () => void;
}

interface TeamInfoData {
  teamId: string;
}

export default function BriefcaseDetails() {
  //url param
  let [searchParams] = useSearchParams();
  const { spaceId } = useParams();

  //
  const dispatch = useDispatch<AppDispatch>();
  const uploadModalRef = useRef<UploadModalREf>();

  // States
  const [tab, setTab] = useState(0);
  const [openShareBriefcaseDrawer, setShareBriefCaseDrawer] = useState(false);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [isAddMemeberModalOpen, setIsAddMemberModalOpen] = useState(false);

  // Store
  const briefcaseDocuments = useSelector(
    (state: RootState) => state.briefcase.briefcaseDocuments
  );
  const { teamInfo } = useSelector((state: RootState) => state.teams);
  const { data } = useSelector((state: RootState) => state.briefcase.briefcase);

  // useEffect
  useEffect(() => {
    dispatch(getTeamInfo());
  }, [dispatch]);

  useEffect(() => {
    if (teamInfo.data && spaceId) {
      const teamInfoData = teamInfo.data as TeamInfoData;
      const teamId = teamInfoData.teamId;
      spaceId && dispatch(getBriefcaseDocuments(spaceId));
      dispatch(getBriefcase({ spaceId, teamId }));
      dispatch(getPresentationHistory(spaceId));
      dispatch(getShareHistory(spaceId));
    }
  }, [spaceId, dispatch, teamInfo.data]);

  //useeffects

  useEffect(() => {
    const tabParam = searchParams.get('tab')?.toLowerCase();
    !tabParam && setTab(0);
    tabParam === 'all-files' && setTab(0);
    tabParam === 'share-history' && setTab(1);
    tabParam === 'presentation-history' && setTab(2);
    tabParam === 'analytics' && setTab(3);
    tabParam === 'audit-trail' && setTab(4);
  }, [searchParams]);

  // functions
  const onClose = () => {
    setShareBriefCaseDrawer(false);
  };
  const handleFileUploadCancel = () => {
    uploadModalRef.current?.resetModal();
    setIsFileUploadModalOpen(false);
  };
  const handleAddmembersCancel = () => {
    uploadModalRef.current?.resetModal();
    setIsAddMemberModalOpen(false);
  };

  //variables
  const items_ = [
    {
      key: '1',
      label: (
        <div className='flex gap-x-2'>
          <img src={divider} alt='rename file' className='w-4' />
          <p>New divider</p>
        </div>
      ),
    },

    {
      key: '2',
      label: (
        <div
          className='flex gap-x-2'
          onClick={() => setIsFileUploadModalOpen(true)}
        >
          <img src={addCircle} alt='metrics file' className='w-4' />
          <p>Upload files</p>
        </div>
      ),
    },

    {
      key: '3',
      label: (
        <div className='flex gap-x-2'>
          <img src={link} alt='request file' className='w-4' />
          <p>Request a file</p>
        </div>
      ),
    },
  ];

  return (
    // <AddMembers />

    <section className='relative'>
      {briefcaseDocuments.isLoading && <Loader />}
      {!briefcaseDocuments.isLoading && (
        <>
          {/* Nav */}
          <nav className='fixed top-0  lg:left-[18%] left-0 z-30 lg:w-[82%] w-full h-20 bg-white pt-4'>
            <section className='w-[95%] mx-auto'>
              <div className='flex items-center justify-between  flex-col lg:flex-row gap-y-4'>
                <section className='flex items-center justify-between w-full lg:w-[30%]'>
                  <img
                    src={menu}
                    alt='open sidenav'
                    className='block lg:hidden'
                    // onClick={handleClick}
                  />
                  <div className='leading-tight text-right lg:text-left justify-items-end justify-self-end lg:self-start'>
                    <h1 className='text-lg font-bold lg:text-2xl font-grotesk'>
                      Briefcase
                    </h1>
                    <p className='lg:text-sm text-xs mt-[-3px]'>
                      Create a briefcase to manage your events and files
                    </p>
                    {/* {breadcrumb} */}
                  </div>
                </section>

                <div className='flex items-center flex-col lg:flex-row gap-y-4 gap-x-2 text-sm font-medium'>
                  {/* Share briefcase */}

                  <div
                    className='px-3 border border-[#AFAFAF] rounded-md text-[#545454] flex gap-x-2 items-center h-8 cursor-pointer'
                    onClick={() => setShareBriefCaseDrawer(true)}
                  >
                    <p>Share briefcase</p>
                    <img src={sendIcon} alt='share briefcase' />
                  </div>
                  {/* Present briefcase */}
                  <div className='px-3 text-[#FF6929] border border-[#D45722] rounded-md flex gap-x-2 items-center h-8 cursor-pointer'>
                    <p>Present briefcase</p>
                    <img src={ChevronOrange} alt='drop down' />
                  </div>
                  <div className='px-2 border rounded-md cursor-pointer bg-gray20 h-8 flex items-center'>
                    <img src={bell} alt='notification' className='w-4' />
                  </div>
                </div>
              </div>
            </section>
          </nav>

          {/* Details banner */}
          <section className='mt-20 relative'>
            <div className='h-[130px] w-full rounded-xl overflow-hidden '>
              <img
                src={briefcaseFrame}
                alt='background frame'
                className='w-full h-full object-cover'
              />
            </div>
            <div className='h-[144px] w-full bg-transparent absolute -bottom-[70%] left-0 px-5'>
              <div className='bg-[#FEFEFD] w-full h-full rounded-xl p-5 border border-[#F6F3EF] relative'>
                <div className='w-full'>
                  <h1 className='text-lg font-bold lg:text-2xl font-grotesk'>
                    {data && data.title}
                  </h1>
                  <section className='flex gap-x-5 items-center text-sm'>
                    <p>Collection of my shared briefcase</p>
                    <div className='flex gap-x-2'>
                      <div className='flex gap-x-2 items-center'>
                        {data &&
                          data.tags
                            ?.split(',')
                            .slice(0, 2)
                            .map((item, index) => (
                              <div
                                className='flex items-center gap-x-2 bg-[#F5F5F5] border border-[#DFDFDF] rounded-full w-fit px-2 py-1'
                                key={index}
                              >
                                <img src={dot} alt='profile' className='' />
                                <div className='text-sm font-medium capitalize'>
                                  <p>{item}</p>
                                </div>
                              </div>
                            ))}
                      </div>
                      {data && data.tags?.split(',').length > 2 && (
                        <div className='flex items-center gap-x-2 bg-[#F5F5F5] border border-[#DFDFDF] rounded-full w-fit px-2 py-1'>
                          <div className='text-sm font-medium capitalize'>
                            <p>+ {data.tags.split(',').length - 2}</p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='flex items-center gap-x-2'>
                      <img src={clock} alt='clock' />
                      <p>Last updated 5 min ago</p>
                    </div>
                  </section>
                  <div className='mt-3 flex gap-x-4'>
                    <AvatarGroup>
                      {data &&
                        data.members?.map((item) => (
                          <Avatar
                            label={getInitials(item.fullName)}
                            shape='circle'
                            size='normal'
                          />
                        ))}
                    </AvatarGroup>
                    <div className='relative'>
                      <button
                        className='bg-white border border-primary rounded-md py-1 flex gap-x-2 px-2 items-center justify-center '
                        onClick={() => {
                          console.log('Button clicked again!!');
                          setIsAddMemberModalOpen(true);
                        }}
                      >
                        <img src={addCircleOrange} alt='add member' />
                        <p className='text-sm text-primary font-medium'>
                          Add member
                        </p>
                      </button>
                      {/* Add members modal */}
                      <AddMembers
                        isAddMemeberModalOpen={isAddMemeberModalOpen}
                        setIsAddMemberModalOpen={setIsAddMemberModalOpen}
                      />
                    </div>
                  </div>
                </div>
                <img
                  src={briefcaseIMG}
                  alt='briefcase'
                  className='absolute right-20 top-0'
                />
                <img
                  src={lines}
                  alt='briefcase'
                  className=' absolute right-0 top-0'
                />
              </div>
            </div>
          </section>

          {/* tabs */}
          <section className='my-20 mt-[110px]'>
            <section className='flex gap-x-4 items-center justify-between'>
              <section className='flex gap-x-2 border-b'>
                <Link
                  to={`/admin/mybriefcase/details/${spaceId}?tab=all-files`}
                >
                  <button
                    onClick={() => setTab(0)}
                    className={`px-4 pb-2 cursor-pointer text-sm  ${
                      tab === 0 && 'border-b-2  text-primary border-b-primary'
                    }`}
                  >
                    All files
                  </button>
                </Link>

                <Link
                  to={`/admin/mybriefcase/details/${spaceId}?tab=share-history`}
                >
                  <button
                    onClick={() => setTab(1)}
                    className={`px-4 pb-2 cursor-pointer text-sm  ${
                      tab === 1 && 'border-b-2  text-primary border-b-primary'
                    }`}
                  >
                    Share history
                  </button>
                </Link>

                <Link
                  to={`/admin/mybriefcase/details/${spaceId}?tab=presentation-history`}
                >
                  <button
                    onClick={() => setTab(2)}
                    className={`px-4 pb-2 cursor-pointer text-sm  ${
                      tab === 2 && 'border-b-2  text-primary border-b-primary'
                    }`}
                  >
                    Presentation history
                  </button>
                </Link>
                <Link
                  to={`/admin/mybriefcase/details/${spaceId}?tab=analytics`}
                >
                  <button
                    onClick={() => setTab(3)}
                    className={`px-4 pb-2 cursor-pointer text-sm  ${
                      tab === 3 && 'border-b-2  text-primary border-b-primary'
                    }`}
                  >
                    Analytics
                  </button>
                </Link>
                <Link
                  to={`/admin/mybriefcase/details/${spaceId}?tab=audit-trail`}
                >
                  <button
                    onClick={() => setTab(4)}
                    className={`px-4 pb-2 cursor-pointer text-sm  ${
                      tab === 4 && 'border-b-2  text-primary border-b-primary'
                    }`}
                  >
                    Audit trail
                  </button>
                </Link>
              </section>
              <section className='flex items-center gap-x-2'>
                <div className='h-8 rounded-md w-8 border border-[#E2E2E2] cursor-pointer flex items-center justify-center'>
                  <img src={ellipsisVertical} alt='ellipsis icon' />
                </div>

                <section onClick={(e) => e.preventDefault()}>
                  <Dropdown
                    trigger={['click']}
                    dropdownRender={(menu) => (
                      <Menu>
                        {items_.map((item) => {
                          return (
                            <Menu.Item key={item.key}>{item.label}</Menu.Item>
                          );
                        })}
                      </Menu>
                    )}
                  >
                    <div className='bg-black text-white text-sm h-8 rounded-md flex gap-x-1 items-center px-5 cursor-pointer'>
                      <img src={plusWhite} alt='ellipsis icon' />
                      <p>Add content</p>
                      <img src={ChevronDownWhite} alt='ellipsis icon' />
                    </div>
                  </Dropdown>
                </section>
              </section>
            </section>

            <section className='my-8 pb-20'>
              {tab === 0 && spaceId && (
                <AllFiles
                  spaceId={spaceId}
                  briefcaseDocuments={briefcaseDocuments.data}
                />
              )}
              {tab === 1 && (
                <ShareHistory briefcaseDocuments={briefcaseDocuments.data} />
              )}
              {tab === 2 && (
                <PresentationHistory
                  briefcaseDocuments={briefcaseDocuments.data}
                />
              )}
              {tab === 3 && (
                <Analytics briefcaseDocuments={briefcaseDocuments.data} />
              )}
              {tab === 4 && (
                <AuditTrail briefcaseDocuments={briefcaseDocuments.data} />
              )}
            </section>
          </section>

          {data?.id && (
            <Drawer
              // title="Basic Drawer"
              placement='right'
              closable={false}
              onClose={onClose}
              open={openShareBriefcaseDrawer}
              width={468}
            >
              <ShareBriefcase spaceId={data.id} />
            </Drawer>
          )}

          {/* Modals */}
          <>
            <Modal
              open={isFileUploadModalOpen}
              onCancel={handleFileUploadCancel}
              footer={null}
            >
              <UploadDocuments
                ref={uploadModalRef}
                closeModal={handleFileUploadCancel}
                spaceId={spaceId}
              />
            </Modal>
          </>
        </>
      )}
    </section>
  );
}
