import React, { useRef, useState } from "react";

//assets
import banner from "assets/svg/banner.svg";
import comments from "assets/svg/comments.svg";
import question from "assets/svg/questions.svg";
import moderator from "assets/svg/moderator.svg";
import projector from "assets/svg/projector.svg";
import watermark from "assets/svg/watermark.svg";
import downloads from "assets/svg/downloads.svg";
import { Switch } from "antd";
import padLock from "assets/svg/padLock.svg";

//libraries
import { Modal } from "antd";

//components
import CommentModal from "views/Admin/Presentation/Modals/Settings/CommentModal";
import { useSelector } from "react-redux";

//slices
import { getSlidesState } from "store/Slides/slidesSlice";

export default function Settings({
  // activeSlide,

  activePlaylist,
}) {
  const { presentationUI } = useSelector(getSlidesState);
  const { activeSlide } = presentationUI ?? {};
  const { note } = activeSlide ?? {};
  //useref
  const commentModalRef = useRef();
  //usestate
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);

  //function
  const handleAddFileCancel = () => {
    setIsCommentModalOpen(false);
  };

  return (
    <section className="absolute hidden h-auto px-4 py-2 bg-white rounded-lg lg:self-start lg:block md:hidden right-10">
      <div>
        <h2 className="text-sm font-bold text-center uppercase border-b text-gray60">
          Settings
        </h2>
      </div>

      <section className="flex flex-col items-center py-4 text-xs font-medium gap-y-8 text-gray60">
        <div className="flex flex-col items-center cursor-pointer gap-y-1">
          <img src={question} alt="" />
          <p>Questions</p>
          <span>
            <Switch />
          </span>
        </div>

        <div
          className="flex flex-col items-center cursor-pointer gap-y-1"
          onClick={() => setIsCommentModalOpen(true)}
        >
          <div className="relative">
            {note && (
              <span className="absolute top-[-4px] right-[-4px] w-2 h-2 bg-red-700 rounded-full"></span>
            )}
            <img src={comments} alt="" />
          </div>

          <p>Comments</p>
        </div>

        <div className="flex flex-col items-center cursor-pointer gap-y-1">
          <img src={downloads} alt="" />
          <p>Downloads</p>
          <span>
            <Switch />
          </span>
        </div>

        {/* <div className="flex flex-col items-center cursor-pointer gap-y-1">
          <img src={watermark} alt="" />
          <p>Watermark</p>
        </div> */}
        <div className="flex flex-col items-center cursor-pointer gap-y-1">
          <img src={padLock} alt="" />
          <p>Watermark</p>
          <span>
            <Switch />
          </span>
        </div>

        <div className="flex flex-col items-center cursor-pointer gap-y-1">
          <img src={banner} alt="" />
          <p>Banner</p>
          <span>
            <Switch />
          </span>
        </div>

        {/* <div className="flex flex-col items-center cursor-pointer gap-y-1">
          <img src={projector} alt="" />
          <p>Projector</p>
        </div> */}

        {/* <div className="flex flex-col items-center cursor-pointer gap-y-1">
          <img src={moderator} alt="" />
          <p>Moderator</p>
        </div> */}
      </section>

      <>
        {/* Modal */}

        <Modal
          open={isCommentModalOpen}
          onCancel={handleAddFileCancel}
          footer={null}
          title="Leave a note"
        >
          <CommentModal
            ref={commentModalRef}
            activeSlide={activeSlide}
            activePlaylist={activePlaylist}
            closeModal={handleAddFileCancel}
          />
        </Modal>
        {/*  */}
      </>
    </section>
  );
}
