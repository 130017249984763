import React, { useState } from 'react';

// Assets
import ellipsis from 'assets/svg/ellipsis-horizontal.svg';
import eyeIcon from 'assets/svg/eye.svg';
// import toggleBlack from 'assets/svg/_Toggle-base-black.svg';
// import toggleWhite from 'assets/svg/_Toggle-base-white.svg';
import copyIcon from 'assets/svg/copy-1.svg';
import eye2 from 'assets/svg/eye-2.svg';

// Libraries
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

export default function PresentationHistory({
  briefcaseDocuments,
}: {
  briefcaseDocuments: any;
}) {
  //Types
  interface RowData {
    name: string;
    link: string;
    visits: number;
    dateCreated: string;
    private: boolean;
  }

  // States
  const [selectedFiles, setSelectedFiles] = useState<RowData[] | null>(null);

  const files = [
    {
      id: '1',
      name: 'Board meeting',
      link: 'thepresenta.com/meeting',
      visits: 23,
      dateCreated: '30 mins ago',
      private: true,
    },
    {
      id: '2',
      name: 'Board meeting',
      link: 'thepresenta.com/meeting',
      visits: 23,
      dateCreated: '5 mins ago',
      private: false,
    },
    {
      id: '3',
      name: 'Board meeting',
      link: 'thepresenta.com/meeting',
      visits: 23,
      dateCreated: '5 mins ago',
      private: false,
    },
  ];

  const columns = [
    {
      header: 'Name',
      field: 'name',
      body: (rowData: RowData) => {
        return (
          <section className=''>
            <section className='flex items-center gap-x-4'>
              <p className='text-black text-sm'>{rowData?.name}</p>
            </section>
          </section>
        );
      },
    },
    {
      header: 'Links',
      field: 'link',
      body: (rowData: RowData) => {
        return (
          <div className='flex gap-x-2 items-center'>
            <p className='text-sm'>{rowData.link}</p>
            <img src={copyIcon} alt='copy' />
          </div>
        );
      },
    },
    {
      header: 'Visits',
      field: 'visits',
      sortable: true,
      body: (rowData: RowData) => {
        return (
          <section className='w-full flex gap-x-2'>
            <div className='flex items-center gap-x-2 bg-[#F6F3EF] border border-[#DFDFDF] rounded-full w-fit px-2'>
              <img src={eyeIcon} alt='profile' className='' />
              <div className='text-sm font-medium capitalize'>
                <p>{rowData.visits}</p>
              </div>
            </div>
          </section>
        );
      },
    },

    {
      header: 'Date created',
      field: 'dateCreated',
      body: (rowData: RowData) => {
        return (
          <div className='flex'>
            <h1>{rowData.dateCreated}</h1>
          </div>
        );
      },
    },

    {
      header: 'Permission',
      field: 'permission',
      body: (rowData: RowData) => {
        return (
          <section className='flex gap-x-5 items-center'>
            <p className='text-sm'>Private</p>
            {/* <img
              src={rowData.private ? toggleBlack : toggleWhite}
              className='cursor-pointer'
              alt='toggle btn'
            /> */}

            <label className='inline-flex items-center cursor-pointer'>
              <input type='checkbox' value='' className='sr-only peer' />
              <div className="relative w-11 h-6 bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-black"></div>
            </label>
          </section>
        );
      },
    },

    {
      header: '',
      sortable: true,
      body: () => {
        return (
          <div className='w-[100px] flex justify-end'>
            <div className='cursor-pointer flex items-center gap-x-5'>
              <img src={eye2} alt='' className='w-5' />
              <img src={ellipsis} alt='' />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {/* Table */}
      <section className='overflow-hidden rounded-xl w-full'>
        <DataTable
          value={files}
          tableStyle={{ minWidth: '45rem', maxHeight: '300px' }}
          size={'normal'}
          selectionMode='checkbox'
          selection={selectedFiles!}
          onSelectionChange={(e) => {
            setSelectedFiles(e.value);
          }}
          dataKey='id'
        >
          <Column selectionMode='multiple' headerStyle={{ width: '3rem' }} />
          {columns.map((col, index) => {
            return (
              <Column
                key={index}
                body={col?.body}
                field={col.field}
                header={col.header}
                headerClassName='bg-+[#F5F5F5F5] text-blue300 font-normal text-sm'
                className='text-sm'
              />
            );
          })}
        </DataTable>
      </section>
    </div>
  );
}
