import React from "react";

//libraries
import { Modal } from "antd";
import { ClipLoader } from "react-spinners";

export default function ConfirmationModal({
  open,
  text,
  func,
  onClose,
  isLoading,
}) {
  return (
    <section>
      <Modal open={open} footer={null} closable={false} onCancel={onClose}>
        <p className="border-b  text-center text-lg font-grotesk font-semibold pb-2">
          Confirmation
        </p>
        <section className="py-6">
          <p className="text-base font-semibold font-grotesk">{text}</p>

          <section className="flex w-full mt-4 gap-x-4">
            <button
              className=" w-full border py-2 text-black  bg-transparent font-grotesk rounded-lg"
              onClick={() => onClose()}
            >
              No
            </button>
            <button
              className=" w-full py-2 text-white  bg-[#FF6929] font-grotesk rounded-lg"
              onClick={() => func()}
            >
              {isLoading ? <ClipLoader color="white" size={12} /> : "Yes"}
            </button>
          </section>
        </section>
      </Modal>
    </section>
  );
}
