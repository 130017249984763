import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Assets
import search from 'assets/svg/search.svg';
import dot from 'assets/svg/_Dot.svg';
import sendSquareBTN from 'assets/svg/send-sqaure-2.svg';
import plusIcon from 'assets/svg/plus-small.svg';
import sortIcon from 'assets/svg/sortIcon.svg';
import docText from 'assets/svg/document-text.svg';
import dots from 'assets/svg/dots.svg';
import trash from 'assets/svg/trash.svg';
import rename from 'assets/svg/rename.svg';
import link from 'assets/svg/link.svg';
import access from 'assets/svg/access.svg';

// Libraries
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { setTab } from 'store/Briefcase/briefcaseTabSlice';
import moment from 'moment';
import { Dropdown, Menu, Modal } from 'antd';
import { AvatarGroup } from 'primereact/avatargroup';
import { Avatar } from 'primereact/avatar';
import CreateTag from './CreateTag';

//
import { getInitials } from 'utils/formatter';
import FileThumbnail from './FileThumbnail';
import {
  removeBriefcase,
  setBriefcaseDetailsAction,
} from 'store/Briefcase/briefcaseSlice';
import { AppDispatch } from 'store/store';
import RenameBriefcase from './RenameBriefcase';

//types
interface Member {
  fullName: string;
  id: string;
  memberId: string;
  spaceId: string;
  spaceTitle: string;
}

interface BriefcaseItems {
  bannerId: string;
  dateCreated: string;
  description: string;
  id: string;
  lastModified: string;
  members: Member[] | null;
  tags: string;
  teamId: string;
  title: string;
}

interface CreateSpaceModalRef {
  resetModal: () => void;
}

export default function BriefcaseList({
  briefcaseItems,
}: {
  briefcaseItems: BriefcaseItems[];
}) {
  //
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const createSpaceModal = useRef<CreateSpaceModalRef>(null);

  // States
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  });
  const [briefcaseDetails, setBriefcaseDetails] =
    useState<BriefcaseItems | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openRenameModal, setOpenRenameModal] = useState(false);

  //function
  const openCreateSpaceModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setOpenRenameModal(false);
    createSpaceModal.current?.resetModal();
  };

  const handleOptionsClick = (index: number, action: string) => {
    if (action === 'delete') {
      console.log(action, 'ran!!');
      const spaceId = briefcaseItems[index].id;
      const archive = false;
      dispatch(removeBriefcase({ spaceId, archive }));
    }
    if (action === 'rename') {
      console.log(action, 'ran!!');
      setBriefcaseDetails(briefcaseItems[index]);
      setOpenRenameModal(true);
    }
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  //variables
  const items = [
    {
      key: '1',
      action: 'rename',
      label: (
        <div className='flex gap-x-2 py2'>
          <img src={rename} alt='rename file' className='w-4' />
          <p>Rename</p>
        </div>
      ),
    },

    {
      key: '2',
      action: 'access',
      label: (
        <div className='flex gap-x-2'>
          <img src={access} alt='access control' className='w-4' />
          <p>Access control</p>
        </div>
      ),
    },

    {
      key: '3',
      action: 'share',
      label: (
        <div className='flex gap-x-2'>
          <img src={link} alt='share file' className='w-4' />
          <p>Share</p>
        </div>
      ),
    },

    {
      key: '4',
      action: 'delete',
      label: (
        <div className='flex gap-x-2' onClick={() => {}}>
          <img src={trash} alt='delete file' className='w-4' />
          <p>Delete</p>
        </div>
      ),
    },
  ];
  const columns = [
    {
      header: 'Name',
      field: 'title',
      body: (rowData: BriefcaseItems, { rowIndex }: { rowIndex: number }) => {
        return (
          <section>
            <section className='flex items-center gap-x-4'>
              <div className='bg-gray-800 text-white text-xs rounded-lg overflow-hidden w-[49px] h-[49px] flex items-center justify-center'>
                <FileThumbnail file={rowData.bannerId} height={49} width={49} />
              </div>
              <div
                className='pt-1 font-medium capitalize cursor-pointer'
                onClick={() => {
                  dispatch(setTab(0));
                  dispatch(setBriefcaseDetailsAction(rowIndex));
                  navigate(`/admin/mybriefcase/details/${rowData.id}`);
                }}
              >
                <p className='text-black text-sm'>{rowData?.title}</p>
                <div className='flex gap-x-1 my-1 text-xs'>
                  <img src={docText} alt='document icon' />
                  <p>100</p>
                  <p>files</p>
                </div>
              </div>
            </section>
          </section>
        );
      },
    },
    {
      header: 'Last updated',
      field: 'lastmodified',
      sortable: true,
      body: (rowData: BriefcaseItems) => {
        const relativeTime = moment(rowData.lastModified).fromNow();
        return (
          <section>
            <p>{relativeTime}</p>
          </section>
        );
      },
    },
    {
      header: 'tags',
      field: 'tags',
      sortable: true,
      body: (rowData: BriefcaseItems) => {
        const listofTags = rowData.tags?.split(',');
        return (
          <section
            className='w-full flex gap-x-2 cursor-pointer'
            onClick={() => {
              if (listofTags) {
                setBriefcaseDetails(rowData);
              }
              openCreateSpaceModal();
            }}
          >
            {listofTags?.length > 0 ? (
              <>
                <div className='flex gap-x-2'>
                  {listofTags.slice(0, 2).map((item, index) => (
                    <div className='flex items-center gap-x-2 bg-[#F5F5F5] border border-[#DFDFDF] rounded-full w-fit px-2 py-0.5'>
                      <img src={dot} alt='profile' className='' />
                      <div className='text-sm font-medium capitalize'>
                        <p className='text-xs'>{item}</p>
                      </div>
                    </div>
                  ))}
                </div>
                {listofTags.length > 2 && (
                  <div className='flex items-center gap-x-2 bg-[#F5F5F5] border border-[#DFDFDF] rounded-full w-fit px-2 py-0.5'>
                    <div className='text-sm font-medium capitalize'>
                      <p>+ {listofTags.length - 2}</p>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className='flex gap-x-1 items-center cursor-pointer'>
                <img src={plusIcon} alt='add tag' />
                <p>add tag</p>
              </div>
            )}
          </section>
        );
      },
    },

    {
      header: 'Access',
      field: 'members',
      sortable: true,
      body: (rowData: BriefcaseItems) => {
        return (
          <div>
            {/* <p>Members</p> */}
            <AvatarGroup>
              {/* <Avatar image={rowData.access[1]} size='normal' shape='circle' />   */}
              {/* <Avatar image={avatar1} size='normal' shape='circle' />
              <Avatar image={avatar2} size='normal' shape='circle' />
              <Avatar image={avatar1} size='normal' shape='circle' /> */}
              {rowData.members?.map((item) => (
                <Avatar
                  label={getInitials(item.fullName)}
                  shape='circle'
                  size='normal'
                />
              ))}
              {/* <Avatar label={rowData.access.} shape='circle' size='normal' /> */}
              {/* <Avatar label='+2' shape='circle' size='normal' /> */}
            </AvatarGroup>
          </div>
        );
      },
    },
    {
      header: '',
      sortable: true,
      body: (rowData: BriefcaseItems, options: { rowIndex: number }) => {
        return (
          <div className='flex gap-x-3 items-center'>
            <div className='cursor-pointer'>
              <img src={sendSquareBTN} alt='' />
            </div>
            <section onClick={(e) => e.preventDefault()}>
              <Dropdown
                trigger={['click']}
                dropdownRender={(menu) => (
                  <Menu>
                    {items.map((item) => {
                      return (
                        <Menu.Item
                          key={item.key}
                          onClick={() => {
                            handleOptionsClick(options.rowIndex, item.action);
                          }}
                        >
                          {item.label}
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                )}
              >
                <div className='cursor-pointer py-4'>
                  <img src={dots} alt='options' />
                </div>
              </Dropdown>
            </section>
          </div>
        );
      },
    },
  ];

  // const handleRowClick = (e: any) => {
  //   console.log('Row Data:', e.data); // Access row data
  //   console.log('Row Index:', e.index); // Access row index
  // };

  return (
    <div>
      {/* search & filter */}
      <section className='mb-4'>
        <section className={`w-full justify-between flex`}>
          <div className='flex justify-center items-center w-28 h-8 rounded-md gap-x-2 bg-gray30 py-1'>
            <img src={sortIcon} alt='sort' />
            <p className='text-[#545454] text-sm font-medium'>All spaces</p>
          </div>
          <div className='flex items-center self-end justify-end w-56 px-4 py-2 text-xs rounded-md gap-x-2 bg-gray30'>
            <img src={search} alt='sort' />
            <input
              placeholder=' search'
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              className='bg-transparent w-[90%] py-1 outline-none'
            />
          </div>
        </section>
      </section>
      {/* Table */}
      <section className='overflow-hidden rounded-xl w-full'>
        <DataTable
          filters={filters}
          globalFilterFields={['name', 'lastUpdated', 'tags']}
          value={briefcaseItems}
          tableStyle={{ minWidth: '45rem', maxHeight: '300px' }}
        >
          {columns.map((col, index) => {
            return (
              <Column
                key={index}
                body={col?.body}
                field={col.field}
                header={col.header}
                headerClassName='bg-[#F5F5F5F5] text-blue300 font-normal text-sm'
                className='text-sm'
              />
            );
          })}
        </DataTable>
      </section>

      {/* Modal */}
      <Modal
        footer={null}
        centered={false}
        open={isModalOpen}
        onCancel={handleCancel}
        style={{
          top: 20,
        }}
      >
        <CreateTag
          closeModal={handleCancel}
          // ref={createSpaceModal}
          details={briefcaseDetails}
        />
      </Modal>

      {/* rename briefcase */}
      {briefcaseDetails && (
        <Modal
          footer={null}
          centered={false}
          open={openRenameModal}
          onCancel={handleCancel}
          style={{
            top: 20,
          }}
        >
          <RenameBriefcase
            closeModal={handleCancel}
            details={briefcaseDetails}
          />
        </Modal>
      )}
    </div>
  );
}
