import { ChangeEvent, useRef, useState } from 'react';

//assets
import linkOrange from 'assets/svg/link-orange.svg';
import lockCircle from 'assets/svg/lock-circle.svg';
import send from 'assets/svg/send-2.svg';
import lockCircleOrange from 'assets/svg/lock-circle-orange.svg';
import sendOrange from 'assets/svg/send-2-orange.svg';
import uploadBulk from 'assets/svg/people2.svg';
import sms from 'assets/svg/sms-tracking.svg';
import calender from 'assets/svg/calendar-edit.svg';
import timer from 'assets/svg/timer3.svg';
import cirlceI from 'assets/svg/circle_i.svg';
import x_icon from 'assets/svg/x.svg';
import placeHolder from 'assets/image/placeholder1.png';
import arrowRight from 'assets/svg/arrow-right-white.svg';
import arrowLeft from 'assets/svg/arrow-left-orange.svg';
import linkSquare from 'assets/svg/link-square.svg';
import previewImg from 'assets/image/previewFile.png';
import previewIcon from 'assets/svg/preview-icon.svg';
import copy from 'assets/svg/link2.svg';
import copyImage from 'assets/image/copy-image.png';

// Libraries
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DatePicker } from 'antd';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';

// Extend dayjs with UTC plugin
dayjs.extend(utc);

//Types
interface RowData {
  name: string;
  link: string;
  visits: number;
  dateCreated: string;
  private: boolean;
}

interface Props {
  spaceId: string;
}

export default function ShareBriefcase({ spaceId }: Props) {
  //
  const emailRef = useRef<HTMLInputElement>(null);
  const domainRef = useRef<HTMLInputElement>(null);

  // {
  //   "title": "string", ----
  //   "documentId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //   "spaceId": "3fa85f64-5717-4562-b3fc-2c963f66afa6", ------
  //   "startTime": "2024-09-22T14:12:48.993Z",
  //   "expiry": "2024-09-22T14:12:48.993Z",
  //   "canDownload": 0,
  //   "type": 0,
  //   "openLink": true,
  //   "emails": [ ----
  //     "string"
  //   ],
  //   "domains": [ ----
  //     "string"
  //   ],
  //   "passcode": "string"
  // }

  // states
  const [tab, setTab] = useState(0);
  const [title, setTitle] = useState('');
  const [emails, setEmails] = useState<string[]>([]);
  const [domains, setDomains] = useState<string[]>([]);
  const [emailName, setEmailName] = useState<string | null>(null);
  const [domainName, setDomainName] = useState<string | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<RowData[]>([]);
  // const [selectedFiles, setSelectedFiles] = useState<RowData[]>([]);
  // const [selectedFiles, setSelectedFiles] = useState<RowData[]>([]);
  const [canDownload, setCandownload] = useState(0);
  // const [startTime, setStartTime] = useState(0);
  const [expires, setExpires] = useState('');
  const [passcode, setPasscode] = useState('');
  const [openLink, setOpenLink] = useState(true);
  const [startTime, setStartTime] = useState('');
  const [isButtonActive, setIsButtonActive] = useState(false);

  // dayjs(scheduledTime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')

  // Functions
  const handleTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
    console.log(title);
  };

  const removeEmail = (index: number) => {
    const updatedTags = emails.filter((_, i) => i !== index);
    setEmails(updatedTags);
  };

  const removeDomain = (index: number) => {
    const updatedTags = domains.filter((_, i) => i !== index);
    setDomains(updatedTags);
  };

  const handleKeyDownEmails = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const email = (e.target as HTMLInputElement).value;
    if (e.key === 'Enter' && email !== '') {
      setEmails((prev) => [...prev, email]);
    }
  };

  const handleKeyDownDomains = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const domain = (e.target as HTMLInputElement).value;
    if (e.key === 'Enter') {
      setDomains((prev) => [...prev, domain]);
      if (emailRef.current?.value) {
        emailRef.current.value = '';
        setDomainName(null);
      }
    }
  };

  const onChange = (value: string, dateString: string | string[]) => {
    const selectedDate = dayjs.utc(dateString as string); // Convert selected date string to dayjs object in UTC
    const formattedDate = selectedDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    setExpires(formattedDate);
    setIsButtonActive(true);
    // console.log("Formatted Selected Time: ", dateString);
  };

  const onOk = (value: string) => {
    console.log('onOk: ', value);
  };

  // const handleShareBriefcase = () => {
  //   const formData = new FormData();
  //   formData.append('title', title);
  //   formData.append('documentId', description);
  //   formData.append('spaceId', spaceId);
  //   formData.append('startTime', startTime);
  //   formData.append('expiry', expires);
  //   formData.append('canDownload', canDownload);
  //   formData.append('type', 0);
  //   formData.append('openLink', true);
  //   formData.append('emails', emails);
  //   formData.append('domains', domains);
  //   formData.append('passcode', passcode);
  // };

  const files = [
    {
      id: '1',
      name: 'Rooney',
      link: 'thepresenta.com/meeting',
      visits: 23,
      dateCreated: '30 mins ago',
      private: true,
    },
    {
      id: '2',
      name: 'Design deck',
      link: 'thepresenta.com/meeting',
      visits: 23,
      dateCreated: '5 mins ago',
      private: false,
    },
    {
      id: '3',
      name: 'Presenta',
      link: 'thepresenta.com/meeting',
      visits: 23,
      dateCreated: '5 mins ago',
      private: false,
    },
    {
      id: '4',
      name: 'Web screenshot',
      link: 'thepresenta.com/meeting',
      visits: 23,
      dateCreated: '5 mins ago',
      private: false,
    },
    {
      id: '5',
      name: 'New logo',
      link: 'thepresenta.com/meeting',
      visits: 23,
      dateCreated: '5 mins ago',
      private: false,
    },
  ];

  const columns = [
    {
      header: 'Name',
      field: 'name',
      body: (rowData: any) => {
        return (
          <section className='w-[400px] cursor-pointer'>
            <section className='flex items-center gap-x-4'>
              <div className='bg-gray-800 text-white text-xs rounded-lg overflow-hidden w-[36px] h-[36px] flex items-center justify-center'>
                <img src={placeHolder} alt='file thumbnail' />
              </div>
              <p className='text-black text-sm'>{rowData?.name}</p>
            </section>
          </section>
        );
      },
    },
  ];

  return (
    <section className='pt-5 pb-20 min-h-[100vh] relative'>
      <div className='w-full'>
        <h1 className='text-xl font-medium'>Share link</h1>
      </div>
      {/* Indicators */}
      <section className=''>
        <div className='flex items-center mt-3 px-4 w-full pt-3 pb-10 border-b border-t '>
          <div className='min-w-10 min-h-10 flex items-center justify-center border border-[#EAECF0] rounded-lg shadow-sm relative'>
            <img src={linkOrange} alt='link' />
            <div className='absolute -bottom-8 left-[50%] -translate-x-[50%] w-[70px]'>
              <p className='text-sm font-semibold'>Create link</p>
            </div>
          </div>
          <hr className={`w-full ${tab !== 0 && 'border border-primary '}`} />
          <div className='min-w-10 min-h-10 flex items-center justify-center border border-[#EAECF0] rounded-lg shadow-sm relative'>
            <img src={tab !== 0 ? lockCircleOrange : lockCircle} alt='link' />
            <div className='absolute -bottom-8 left-[50%] -translate-x-[50%] w-[130px]'>
              <p className='text-sm text-[#333333]'>Manage permissions</p>
            </div>
          </div>
          <hr className={`w-full ${tab === 2 && 'border border-primary '}`} />
          <div className='min-w-10 min-h-10 flex items-center justify-center border border-[#EAECF0] rounded-lg shadow-sm relative'>
            <img src={tab !== 2 ? send : sendOrange} alt='link' />
            <div className='absolute -bottom-8 left-[50%] -translate-x-[50%] w-[95px]'>
              <p className='text-sm text-[#333333]'>Share your link</p>
            </div>
          </div>
        </div>
      </section>

      {/* First Tab */}
      {tab === 0 && (
        <section className=''>
          <div className='flex flex-col gap-y-1 mt-5'>
            <label htmlFor='space' className='font-normal text-gray700'>
              Enter tag name
            </label>
            <input
              type='text'
              value={title}
              placeholder='e.g for external board members'
              className='py-3 pl-4 text-sm rounded-lg outline-none bg-orange10'
              onChange={(e) => handleTitle(e)}
            />
          </div>
          {/* share link */}
          <section className='border rounded-xl border-[#EEEEEE] p-3 mt-3'>
            <div className='w-full flex justify-between items-center mt-3'>
              <p className='text-base'>Share link with anyone</p>
              <label className='inline-flex items-center cursor-pointer'>
                <input type='checkbox' value='' className='sr-only peer' />
                <div className="relative w-11 h-6 bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-black"></div>
              </label>
            </div>
            {/* add email */}
            <div className='grid grid-cols-9 gap-x-2 mt-3'>
              <label
                htmlFor='add-email'
                className='col-span-6 rounded-md overflow-hidden flex bg-[#F5F5F5] pl-3 items-center'
              >
                <img src={sms} alt='email' className='w-4 h-4' />
                <input
                  type='text'
                  id='add-email'
                  placeholder='add email'
                  className='py-3 pl-1 pr-3 text-sm rounded-lg outline-none bg-[#F5F5F5] w-full'
                  ref={emailRef}
                  onKeyDown={handleKeyDownEmails}
                  onChange={(e) => setEmailName(e.target.value)}
                />
              </label>
              <button className='flex gap-x-2 items-center px-3 py-2 col-span-3 w-full bg-[#F5F5F5] rounded-md'>
                <img src={uploadBulk} alt='upload' className='h-4 w-4' />
                <p className='text-sm text-[#6B6B6B]'>upload bulk</p>
              </button>
            </div>
            {/* email... */}
            <section className='flex flex-wrap my-2 gap-y-2 gap-x-3'>
              {emails?.map((email, index) => {
                return (
                  <div
                    key={index}
                    className='flex items-center px-4 py-1 border rounded-full text-gray500 bg-gray30 gap-x-2 border-gray180'
                  >
                    <p>{email}</p>
                    <i
                      className='text-xs cursor-pointer pi pi-times mt-[2px]'
                      onClick={() => removeEmail(index)}
                    ></i>
                  </div>
                );
              })}
            </section>
          </section>

          {/* use password */}
          <section className='border rounded-xl border-[#EEEEEE] p-3 mt-3'>
            <div className='w-full flex justify-between items-center mt-3'>
              <p className='text-base'>Use password</p>
              <label className='inline-flex items-center cursor-pointer'>
                <input type='checkbox' value='' className='sr-only peer' />
                <div className="relative w-11 h-6 bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-black"></div>
              </label>
            </div>
            {/* enter password */}
            <div className='w-full mt-3'>
              <label
                htmlFor='add-email'
                className='w-full rounded-md overflow-hidden flex bg-[#F5F5F5] pl-3 items-center'
              >
                <img src={sms} alt='email' className='w-4 h-4' />
                <input
                  type='text'
                  id='add-email'
                  placeholder='Enter password'
                  className='py-3 pl-1 pr-3 text-sm rounded-lg outline-none bg-[#F5F5F5] w-full'
                  // ref={domainRef}
                  // onKeyDown={handleKeyDown}
                  // onChange={(e) => setTagName(e.target.value)}
                />
              </label>
            </div>
          </section>

          {/* Link expires */}
          <section className='border rounded-xl border-[#EEEEEE] p-3 mt-3'>
            <div className='w-full flex justify-between items-center mt-3'>
              <p className='text-base'>Link expires</p>
              <label className='inline-flex items-center cursor-pointer'>
                <input type='checkbox' value='' className='sr-only peer' />
                <div className="relative w-11 h-6 bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-black"></div>
              </label>
            </div>
            {/* expires */}
            <div className='w-full mt-3 grid grid-cols-2 gap-x-2'>
              <div className='col-span-1 bg-[#F5F5F5] w-full py- rounded-md px flex items-center gap-x-2'>
                {/* <img src={calender} alt='calender' className='w-4 h-4' />
                <p className='text-sm text-[#6B6B6B]'>20th March 2024</p> */}
                <DatePicker
                  showTime
                  onOk={onOk}
                  minDate={dayjs()}
                  onChange={onChange}
                  format='YYYY-MM-DD HH:mm'
                  // defaultValue={dayjs(scheduledTime)}
                  className='flex justify-between px-4 py-2 mt-1 rounded-md text-gray700 bg-orange10'
                />
              </div>
              <div className='col-span-1 bg-[#F5F5F5] w-full py-3 rounded-md px-3 flex items-center gap-x-2'>
                <img src={timer} alt='timer' />
                <p className='text-sm text-[#6B6B6B]'>8:30 AM</p>
              </div>
            </div>
          </section>

          {/* Allow downloads */}
          <section className='border rounded-xl border-[#EEEEEE] p-3 mt-3'>
            <div className='w-full flex justify-between items-center'>
              <p className='text-base'>Allow downloads</p>
              <label className='inline-flex items-center cursor-pointer'>
                <input type='checkbox' value='' className='sr-only peer' />
                <div className="relative w-11 h-6 bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-black"></div>
              </label>
            </div>
          </section>

          {/* More security */}
          <section className='border rounded-xl border-[#EEEEEE] p-3 mt-3'>
            <h2 className='text-base font-semibold'>More security</h2>
            <div className='w-full flex justify-between items-center mt-3'>
              <p className='text-base'>Restrict domain</p>
              <label className='inline-flex items-center cursor-pointer'>
                <input type='checkbox' value='' className='sr-only peer' />
                <div className="relative w-11 h-6 bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-black"></div>
              </label>
            </div>
            {/* add domain */}
            <div className='grid grid-cols-9 gap-x-2 mt-3'>
              <label
                htmlFor='add-domain'
                className='col-span-6 rounded-md overflow-hidden flex bg-[#F5F5F5] pl-3 items-center'
              >
                <img src={sms} alt='domain' className='w-4 h-4' />
                <input
                  type='text'
                  id='add-email'
                  placeholder='Add domain'
                  className='py-3 pl-1 pr-3 text-sm rounded-lg outline-none bg-[#F5F5F5] w-full'
                  ref={domainRef}
                  onKeyDown={handleKeyDownDomains}
                  onChange={(e) => setDomainName(e.target.value)}
                />
              </label>
              <button className='flex gap-x-2 items-center px-3 py-2 col-span-3 w-full bg-[#F5F5F5] rounded-md'>
                <img src={uploadBulk} alt='upload' className='h-4 w-4' />
                <p className='text-sm text-[#6B6B6B]'>upload bulk</p>
              </button>
            </div>
            {/* email... */}
            <section className='flex flex-wrap my-2 gap-y-2 gap-x-3'>
              {domains?.map((domain, index) => {
                return (
                  <div
                    key={index}
                    className='flex items-center px-4 py-1 border rounded-full text-gray500 bg-gray30 gap-x-2 border-gray180'
                  >
                    <p>{domain}</p>
                    <i
                      className='text-xs cursor-pointer pi pi-times mt-[2px]'
                      onClick={() => removeDomain(index)}
                    ></i>
                  </div>
                );
              })}
            </section>
          </section>
        </section>
      )}

      {/* Second Tab */}
      {tab === 1 && (
        <section className='px-5'>
          <div className='p-3 bg-[#333333] h-20 flex gap-x-4 justify-between items-center'>
            <img src={cirlceI} alt='review!' className='w-6' />
            <p className='text-white text-sm'>
              Review the files you would love to share before creating a link
            </p>
            <img src={x_icon} alt='close' className='w-4' />
          </div>

          {/* Table */}
          <section className='overflow-hidden rounded-xl w-full mt-5 p-3 bg-[#F9F7F4]'>
            <DataTable
              value={files}
              tableStyle={{ minWidth: '45rem', maxHeight: '300px' }}
              size={'normal'}
              selectionMode='checkbox'
              selection={selectedFiles}
              onSelectionChange={(e) => {
                setSelectedFiles(e.value);
              }}
              dataKey='id'
            >
              <Column
                selectionMode='multiple'
                headerStyle={{ width: '3rem' }}
              />
              {columns.map((col, index) => {
                return (
                  <Column
                    key={index}
                    body={col?.body}
                    field={col.field}
                    header={col.header}
                    headerClassName='bg-[#F5F5F5F5] text-blue300 font-normal text-sm'
                    className='text-sm'
                  />
                );
              })}
            </DataTable>
          </section>
        </section>
      )}

      {/* Third Tab */}
      {tab === 2 && (
        <section>
          <section className='p-3 bg-[#E6F2ED] h-20 flex gap-x-4 rounded-xl items-center '>
            <img src={linkSquare} alt='review!' className='w-6' />
            <div className=''>
              <p className='text-sm text-black font-semibold'>
                Link created successfully
              </p>
              <p>thepresenta/view/alkdjkjskj</p>
            </div>
          </section>

          <section className='rounded-md border p-3 flex flex-col gap-y-2 mt-5'>
            <img src={previewImg} alt='preview file' className='w-[83px]' />
            <p>See how your file will look like to the public</p>
            <button className='flex gap-x-2 bg-[#F5F5F5] py-2 px-5 rounded-md w-fit'>
              <img src={previewIcon} alt='preveiw botton' />
              <p className='text-black'>Preview file</p>
            </button>
          </section>

          <section className='rounded-md border p-3 flex flex-col gap-y-2 mt-5'>
            <img src={copyImage} alt='preview file' className='w-[57px]' />
            <p>Copy to your clipboard</p>
            <button className='flex gap-x-2 bg-[#F5F5F5] py-2 px-5 rounded-md w-fit'>
              <img src={copy} alt='copy botton' />
              <p className='text-black'>Copy link</p>
            </button>
          </section>
        </section>
      )}

      {/* Next and previous */}
      <section className='absolute bottom-0 right-0 w-full border-y h-[66px] flex justify-end px-5 items-center'>
        {tab === 0 && (
          <button
            className='bg-primary text-white h-10 px-5 flex gap-x-2 items-center justify-center rounded-md '
            onClick={() => setTab(1)}
          >
            <p className='font-semibold'>Next</p>
            <img src={arrowRight} alt='next' />
          </button>
        )}
        {tab === 1 && (
          <div className='flex gap-x-4'>
            <button
              className='bg-white border border-primary rounded-md text-primary h-10 px-5 flex gap-x-2 items-center justify-center'
              onClick={() => setTab(0)}
            >
              <img src={arrowLeft} alt='previous' />
              <p className='font-semibold'>Previous</p>
            </button>
            <button
              className='bg-primary text-white h-10 px-5 flex gap-x-2 items-center justify-center rounded-md '
              onClick={() => setTab(2)}
            >
              <p className='font-semibold'>Creat link</p>
              <img src={arrowRight} alt='next' />
            </button>
          </div>
        )}
      </section>
    </section>
  );
}
