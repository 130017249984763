import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

//library
import { Modal, Tooltip } from "antd";

//assets
import add from "assets/svg/addFile.svg";
import trash from "assets/svg/delete.svg";

//component
import DocuementList from "./DocuementList";
import AddFile from "views/Admin/Presentation/Modals/AddDocuments/AddFile";
import { trimText } from "utils/formatter";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  closestCorners,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

export default function Playlist({
  documentList,
  setRemoveDocId,
  activePlaylist,
  setConfirmDelete,
  setActivePlaylist,
}) {
  const { presentationId } = useParams();

  const playlistDropdownRef = useRef(null);

  //usestate
  const [showPlaylist, setShowPlaylist] = useState(false);
  const [isAddFileModalOpen, setIsAddFileModalOpen] = useState(false);
  const [transitionDragID, setTransitionDragID] = useState("");

  const dragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };

  //useeffect
  useEffect(() => {
    const handleClosePlaylist = (e) => {
      if (
        playlistDropdownRef.current &&
        !playlistDropdownRef.current.contains(e.target)
      ) {
        setShowPlaylist(false);
      }
    };

    document.addEventListener("mousedown", handleClosePlaylist);

    return () => {
      document.removeEventListener("mousedown", handleClosePlaylist);
    };
  }, []);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  //functions
  const handleAddFileCancel = () => {
    setIsAddFileModalOpen(false);
  };

  const dragEnter = (e, id) => {
    e.preventDefault();
    setTransitionDragID(id);
  };

  return (
    <section>
      {/* large-screen  */}
      <section className="[ lg:flex md:hidden hidden ] items-center px-6 py-2 text-xs border rounded-lg gap-x-6 ">
        <div className="flex gap-x-5">
          <DndContext
            sensors={sensors}
            onDragEnd={() => {}}
            collisionDetection={closestCorners}
          >
            <section onDragOver={dragOver} className="flex gap-2 items-center">
              <SortableContext
                items={documentList}
                strategy={horizontalListSortingStrategy}
              >
                {documentList?.map((doc) => {
                  const { title, type, id } = doc ?? {};
                  return (
                    <DocuementList
                      key={doc?.id}
                      doc={doc}
                      setRemoveDocId={setRemoveDocId}
                      activePlaylist={activePlaylist}
                      setConfirmDelete={setConfirmDelete}
                      setActivePlaylist={setActivePlaylist}
                    />
                  );
                })}
              </SortableContext>
            </section>
          </DndContext>
        </div>

        <section className="pl-3 border-l-2">
          <div
            onClick={() => setIsAddFileModalOpen(true)}
            className="flex flex-col cursor-pointer gap-y-1 "
          >
            <div className="flex justify-center w-full ">
              <img src={add} alt="add file" className="w-5 " />
            </div>

            <p>Add File</p>
          </div>
        </section>
      </section>
      {/*  */}

      {/* ipad  */}

      <section className="relative block md:block lg:hidden ">
        <button
          onClick={() => setShowPlaylist(!showPlaylist)}
          className="flex items-center justify-between px-4 py-1 border gap-x-2 w-[12rem]"
        >
          <p>Playlist</p>
          <i className="pi pi-chevron-down"></i>
        </button>

        {showPlaylist && (
          <section
            className="absolute w-[18rem] bg-white top-20 py-4 pl-2 rounded-md"
            ref={playlistDropdownRef}
          >
            <section className="flex flex-col gap-y-2">
              <DndContext
                sensors={sensors}
                onDragEnd={() => {}}
                collisionDetection={closestCorners}
              >
                <section
                  className="w-[80%]  mx-auto flex flex-col gap-y-8"
                  onDragOver={dragOver}
                >
                  <SortableContext
                    items={documentList}
                    strategy={horizontalListSortingStrategy}
                  >
                    {documentList?.map((doc) => {
                      const { title, type, id } = doc ?? {};
                      return (
                        <Tooltip key={id} placement="top" title={title ?? type}>
                          <div
                            onDragEnter={(e) => dragEnter(e, id)}
                            onClick={() => setActivePlaylist(doc)}
                            className={`flex items-center  px-2 py-1 justify-between rounded cursor-pointer  ${
                              activePlaylist?.id === doc?.id && "  text-primary"
                            } `}
                          >
                            <p className="mt-1">
                              {trimText(title ?? type, 25)}
                            </p>

                            <img
                              src={trash}
                              alt="remove"
                              className="w-4 cursor-pointer"
                              onClick={() => {
                                setRemoveDocId(doc.documentId);
                                setConfirmDelete(true);
                              }}
                            />
                          </div>
                        </Tooltip>
                      );
                    })}
                  </SortableContext>
                </section>
              </DndContext>

              <div
                onClick={() => setIsAddFileModalOpen(true)}
                className="flex items-center ml-2 cursor-pointer gap-x-2 "
              >
                {/* <img src={add} alt="add file" className="w-4 " />s */}

                <p>Add File</p>
              </div>
            </section>
          </section>
        )}
      </section>
      {/*  */}

      {/* modal  */}
      <Modal
        open={isAddFileModalOpen}
        onCancel={handleAddFileCancel}
        footer={null}
        width={1200}
        centered
      >
        <AddFile
          closeModal={handleAddFileCancel}
          presentationId={presentationId}
        />
      </Modal>
      {/*  */}
    </section>
  );
}
