import React, { useEffect, useRef, useState } from 'react';

//assets
import addPlus from 'assets/svg/add-square.svg';
import tagIcon from 'assets/svg/tag-icon.svg';

//libraries
import { ClipLoader } from 'react-spinners';
import { useDispatch } from 'react-redux';
import { getBriefcases, updateBriefcase } from 'store/Briefcase/briefcaseSlice';
import { AppDispatch } from 'store/store';

interface Member {
  fullName: string;
  id: string;
  memberId: string;
  spaceId: string;
  spaceTitle: string;
}

interface BriefcaseItems {
  bannerId: string;
  dateCreated: string;
  description: string;
  id: string;
  lastModified: string;
  members: Member[] | null;
  tags: string;
  teamId: string;
  title: string;
}

interface RenameBriefcaseProps {
  details: BriefcaseItems;
  closeModal: () => void;
}

export default function RenameBriefcase(props: RenameBriefcaseProps) {
  const { details, closeModal } = props ?? {};
  const { tags, id, title, description, bannerId, teamId } = details ?? {};

  const dispatch = useDispatch<AppDispatch>();
  //useref
  const tagRef = useRef<HTMLInputElement>(null);

  //usestate
  const [titleName, setTitleName] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  //useeffect
  useEffect(() => {
    setTitleName(title);
  }, [title]);

  const updateTitle = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('SpaceId', id);
    formData.append('Title', titleName);
    formData.append('Description', description);
    formData.append('Tags', tags);
    formData.append('Banner', bannerId);

    dispatch(updateBriefcase(formData)).then(() => {
      setIsLoading(false);
      closeModal();
      dispatch(getBriefcases(teamId));
    });
  };

  return (
    <section className='py-3'>
      <div className='mt-[-20px]'>
        <img src={tagIcon} alt='' />
      </div>

      <section>
        <div className='mt-4'>
          <h1 className='text-2xl font-semibold'>Briefcase title</h1>
        </div>

        <section>
          <section className='flex flex-wrap my-2 gap-y-2 gap-x-3'></section>

          {/* form  */}
          <section className='mt-4'>
            <div className='flex flex-col gap-y-1'>
              <label htmlFor='space' className='font-normal text-gray700'>
                Enter tag name
              </label>
              <input
                type='text'
                ref={tagRef}
                placeholder='e.g design'
                value={titleName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setTitleName(e.target.value)
                }
                className='py-3 pl-4 text-sm rounded-lg outline-none bg-orange10'
              />
            </div>

            <section className='flex my-4 mt-6 gap-x-4'>
              <button
                disabled={isLoading}
                onClick={updateTitle}
                className={`flex items-center justify-center w-full px-4 py-3 text-white  ${
                  isLoading ? 'opacity-40 cursor-not-allowed' : 'opacity-100'
                } bg-black rounded-lg gap-x-2`}
              >
                {!isLoading ? (
                  <>
                    <img src={addPlus} alt='' />
                    Update title
                  </>
                ) : (
                  <ClipLoader size={12} color='white' />
                )}
              </button>
            </section>
          </section>
        </section>
      </section>
    </section>
  );
}
